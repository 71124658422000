import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { thunk } from 'redux-thunk';
import { createApiMiddleware, wsMiddleware } from 'componentlibrary';
import rootReducer from '../reducers';
import { authDefaultState } from '../reducers/auth';
import { teamDefaultState } from '../reducers/team';
import { memberDefaultState } from '../reducers/member';
import { riskProfileDefaultState } from '../reducers/riskProfile';
import { taskDefaultState } from '../reducers/task';
import { riskDefaultState } from '../reducers/risk';
import { companyDefaultState } from '../reducers/company';

export const initialStore = {
  auth: authDefaultState(),
  team: teamDefaultState(),
  member: memberDefaultState(),
  riskProfile: riskProfileDefaultState(),
  task: taskDefaultState(),
  risk: riskDefaultState(),
  company: companyDefaultState()
};

const middlewares = [thunk, createApiMiddleware, wsMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const validMiddlewares = middlewares.filter(mw => typeof mw === 'function');

export default createStore(
  rootReducer,
  initialStore,
  applyMiddleware(...validMiddlewares)
);
